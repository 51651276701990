<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  created() {},
  destroyed() {
    
  },
  methods: {},
};
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
#app {
  min-width: 1200px;
  background: #f8f8f8;
  // overflow: hidden;
}
</style>
